const jobList = [
  "Navy Agniveer SSR / MR INET 2025 Online Form",
  "IRDAI Assistant Manager Online Form 2024",
  "CISF Constable / Fire Online Form",
  "IBPS PO 14th Online Form 2024 Extended",
  "Jharkhand TET JHTET Online Form Extended",
  "PGCIL Apprentices Online Form 2024",
  "Nainital Bank PO & Other Post Online Form 2024",
  "Supreme Court Junior Court Attendant Online Form 2024",
  "Airforce Agniveer Non Combatant Recruitment 2024",
  "Railway RRB Paramedical Post Online Form 2024",
  "RRC NR Delhi Apprentices Online Form 2024",
  "Jharkhand Sachivalaya JSSC Stenographer Online Form 2024",
  "Haryana HSSC Primary Teacher PRT Online Form 2024",
  "JSSC Intermediate Level Online Form 2024",
  "Allahabad High Court Research Officer Online Form 2024",
  "ITBP Constable Pioneer Online Form 2024",
  "ITBP HC Dresser Veterinary, Constable Animal Transport Online Form 2024",
  "Indian Maritime University IMU Assistant Online Form 2024",
  "MPESB ITI Training Officer Online Form 2024",
  "Institute for Plasma Research MTS Online Form 2024",
  "RSMSSB Graduate Level CET Online Form 2024",
  "Railway RRC WCR Apprentices Online Form 2024",
  "HPSC Haryana Assistant Professor Online Form 2024",
  "SSC Junior Hindi Translator JHT Online Form 2024",
  "RRB Junior Engineer JE Online Form 2024",
  "Uttarakhand TET UTET Online Form 2024",
];

export default jobList;
